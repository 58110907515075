<script>
// import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
// import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { ref, onMounted} from '@vue/composition-api'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  page: {
    title: "Create Incoming",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {Layout,PageHeader},
  setup() {
     const { 
       get,
    } = useFormResource({ url: 'master/incoming', localeContextPath: '', redirectPathName: 'appsmd-port' })

       const tableData = ref({})

       const loadoutstandingorder = async () => {
         const {data}= await get({ url: 'master/order'})
        tableData.value = data
        console.log(tableData)
      }

     onMounted(() => {
      loadoutstandingorder();
      // getTypes()
      // LOV.value.details = [
      //   {
      //     value: 'none',
      //     name: i18n.t('apps.masterDataCOA.alert.standalone')
      //   },
      //   {
      //     value: 'child',
      //     name: i18n.t('apps.masterDataCOA.alert.child')
      //   }
      // ]
    })

    return{
      get,
      loadoutstandingorder,
    tableData
  }

  },

  

  data() {
    return {
      title: "Create Incoming",
      items: [],
     fields: [
        { key: "ord_no", label: "Customer", sortable: true },
        { key: "ord_smu_id", label: "SMU. No", sortable: true },
        { key: "ord_weight", label: "Chargeable Weight", sortable: true },
        { key: "ord_colly", label: "Collies Ordered", sortable: true }
      ]
    };
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <form class="form-horizontal" role="form">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <b-form-group
                      label-cols-sm="2"
                      label-cols-lg="2"
                      label="Date"
                      label-for="mvs_datefrom"
                    >
                    <b-form-input id="mvs_datefrom"  value="2021-03-01" readonly></b-form-input>
                      <!-- <date-picker v-model="autoClose" :first-day-of-week="1" lang="en" name="mvs_datefrom" id="mvs_datefrom" value="2021-03-01"></date-picker> -->
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="2"
                      label-cols-lg="2"
                      label="Vendor"
                      label-for="vendflight_vendorid"
                    >
                    <b-form-input id="mvs_vendor"  value="Tri-MG" readonly></b-form-input>
                      <!-- <multiselect name="mvs_vendor" id="mvs_vendor" v-model="optvalue" :options="options_vendor"></multiselect> -->
                    </b-form-group>

                    <b-form-group
                      label-cols-sm="2"
                      label-cols-lg="2"
                      label="Flight"
                      label-for="mvs_dateto"
                    >
                    <b-form-input id="mvs_flight"  value="GM726 12:00-14:30" readonly></b-form-input>
                      <!-- <multiselect name="mvs_flight" id="mvs_flight" v-model="optvalue2" :options="options_flight"></multiselect> -->
                    </b-form-group>
                    
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->


      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU No."
                    label-for="customer_contactphone"
                  >
                    <b-form-input id="mvs_processadd"  value="000-123123002"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Collies"
                    label-for="customer_contactphone"
                  >
                    <b-form-input id="mvs_processaddqty"  value="3"></b-form-input>
                    <b-button variant="primary">Add</b-button>
                  </b-form-group>
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU No."
                    label-for="customer_contactphone"
                  >
                    <b-form-input id="mvs_processadd"  value=""></b-form-input>
                    <b-button variant="danger">Remove</b-button>
                  </b-form-group>
            </div>
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Process</h4>
              <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Customer</th>
                            <th>SMU No.</th>
                            <th>Chargeable Weight</th>
                            <th>Collies Ordered</th>
                            <th>Collies Receive</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>PT A Express</td>
                            <td>000-123123001</td>
                            <td>112</td>
                            <td>5</td>
                            <td><b-form-input value=""></b-form-input></td>
                            <td>
                              <b-button size="sm" variant="danger">
                                <i class="ri-delete-bin-line"></i>
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- end table -->
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Outstanding</h4>
              <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                      <b-table
                       :items="tableData"
                        :fields="fields"
                        responsive="sm">
                        </b-table>
                    </div>
                    <!-- end table -->
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
    </form>
  </Layout>
</template>